<template>
  <span class="badge font-weight-normal" :class="statusClass">{{statusText}}</span>
</template>
<script>
import moment from '@/helpers/moment'

export default {
  name: 'RoundStatus',
  props: ['round'],
  computed: {
    status() {
      if(!this.round)
        return null

      if(this.round.status === 'Open') {
        if(moment().isBefore(this.round.roundDate.open)) {
          return 'Waiting'
        }else
        if(moment().isBefore(this.round.roundDate.close)) {
          return 'Open'
        }else{
          return 'Close'
        }
      }else
      if(this.round.status === 'Close') {
        return 'Close'
      }else
      if(this.round.status === 'Cancel') {
        return 'Cancel'
      }else
      if(this.round.status === 'Refund') {
        return 'Refund'
      }else
      if(this.round.status === 'Complete') {
        return 'Complete'
      }else
      if(this.round.status === 'Paid') {
        return 'Paid'
      }
    },
    statusClass() {
      return {
        Waiting: 'badge-warning',
        Open: 'badge-success',
        Close: 'badge-danger',
        Cancel: 'badge-danger',
        Refund: 'badge-danger',
        Complete: 'badge-info',
        Paid: 'badge-primary'
      }[this.status]
    },
    statusText() {
      return {
        Waiting: 'รอเปิดแทง',
        Open: 'เปิดรับแทง',
        Close: 'ปิดรับแทง',
        Cancel: 'ยกเลิก',
        Refund: 'คืนเงิน',
        Complete: 'กำลังออกผล',
        Paid: 'จ่ายเงินแล้ว'
      }[this.status]
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100% !important;
}
</style>
